import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './EditPhotoTask.module.css';

const EditPhotoTask = () => {
  const [task, setTask] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [welcomeText, setWelcomeText] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const { taskId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/admin/tasks/${taskId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTask(response.data);
      setPhotos(response.data.photos || []);
      setWelcomeText(response.data.welcomeText || '');
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError('Failed to fetch task details. Please try again.');
    }
  };

  const handleFileChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append('photo', uploadedFile);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/admin/tasks/${taskId}/upload`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });
      setPhotos([...photos, { filename: response.data.filename, caption: '' }]);
      setUploadedFile(null);
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError('Failed to upload photo. Please try again.');
    }
  };

  const handleCaptionChange = (index, caption) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index].caption = caption;
    setPhotos(updatedPhotos);
  };

  const handleDelete = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/admin/tasks/${taskId}`, { 
        ...task, 
        photos,
        welcomeText
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/admin');
    } catch (error) {
      console.error('Error saving task:', error);
      setError('Failed to save task. Please try again.');
    }
  };

  if (!task) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.container}>
      <h2>Edit Photo Task: {task.name}</h2>
      <div className={styles.welcomeTextContainer}>
        <h3>Welcome Text</h3>
        <textarea
          value={welcomeText}
          onChange={(e) => setWelcomeText(e.target.value)}
          placeholder="Enter welcome text for the task"
          className={styles.welcomeTextArea}
        />
      </div>
      <div className={styles.uploadContainer}>
        <input type="file" onChange={handleFileChange} accept="image/*" />
        <button onClick={handleUpload}>Upload Photo</button>
      </div>
      {photos.map((photo, index) => (
        <div key={index} className={styles.photoItem}>
          <img 
            src={`/uploads/${taskId}/${photo.filename}`} 
            alt={`Upload ${index}`} 
            className={styles.photoPreview}
          />
          <input 
            type="text" 
            value={photo.caption} 
            onChange={(e) => handleCaptionChange(index, e.target.value)} 
            placeholder="Enter caption"
            className={styles.captionInput}
          />
          <button onClick={() => handleDelete(index)} className={styles.deleteButton}>Delete</button>
        </div>
      ))}
      <button onClick={handleSave} className={styles.saveButton}>Save Changes</button>
      <button onClick={() => navigate('/admin')} className={styles.cancelButton}>Cancel</button>
    </div>
  );
};

export default EditPhotoTask;