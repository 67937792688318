import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './Admin.module.css';

const TaskSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

const Admin = () => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [generatedPassword, setGeneratedPassword] = useState('');

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [deletePassword, setDeletePassword] = useState('');

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/admin/tasks', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTasks(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to fetch tasks. Please try again.');
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createTask = async (values, { setSubmitting, resetForm }) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/admin/tasks', values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      resetForm();
      fetchTasks();
      setError(null);
    } catch (error) {
      console.error('Error creating task:', error);
      setError('Failed to create task. Please try again.');
    }
    setSubmitting(false);
  };

const initiateDeleteTask = (task) => {
    setTaskToDelete(task);
    setShowDeleteConfirm(true);
  };

  const confirmDeleteTask = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/admin/tasks/${taskToDelete.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchTasks();
      setError(null);
      setShowDeleteConfirm(false);
      setTaskToDelete(null);
    } catch (error) {
      console.error('Error deleting task:', error);
      setError('Failed to delete task. Please try again or re-login if the problem persists.');
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };



  const handleGeneratePassword = async () => {
    if (selectedTaskId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('/api/generate-password', 
          { date: selectedDate, taskId: selectedTaskId },
          { headers: { Authorization: `Bearer ${token}` }}
        );
        setGeneratedPassword(response.data.password);
        setError(null);
      } catch (error) {
        console.error('Error generating password:', error);
        setError('Failed to generate password. Please try again.');
      }
    } else {
      setError('Please select a Task ID');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (isLoading) {
    return <div className={styles.container}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.container}>Error: {error}</div>;
  }

 return (
    <div className={styles.container}>
      <h1>Admin Dashboard</h1>
      <button onClick={logout} className={styles.logoutButton}>Logout</button>
      
      <h2>Create New Task</h2>
      <Formik
        initialValues={{ id: '', name: '', type: '' }}
        validationSchema={TaskSchema}
        onSubmit={createTask}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className={styles.form}>
            <Field name="id" placeholder="Task ID" className={styles.input} />
            <ErrorMessage name="id" component="div" className={styles.errorMessage} />
            <Field name="name" placeholder="Task Name" className={styles.input} />
            <ErrorMessage name="name" component="div" className={styles.errorMessage} />
            <Field as="select" name="type" className={styles.select}>
              <option value="">Select Task Type</option>
              <option value="treasure-hunt">Treasure Hunt</option>
              <option value="photo-recreation">Photo Recreation</option>
            </Field>
            <ErrorMessage name="type" component="div" className={styles.errorMessage} />
            <button type="submit" disabled={isSubmitting} className={styles.button}>Create Task</button>
          </Form>
        )}
      </Formik>

      <h2>Generate Password for Specific Date</h2>
      <div className={styles.passwordGenerator}>
        <div>
          <label htmlFor="dateSelect">Select Date: </label>
          <input
            type="date"
            id="dateSelect"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className={styles.input}
          />
        </div>
        <div>
          <label htmlFor="taskSelect">Select Task: </label>
          <select
            id="taskSelect"
            value={selectedTaskId}
            onChange={(e) => setSelectedTaskId(e.target.value)}
            className={styles.select}
          >
            <option value="">-- Select a Task --</option>
            {tasks.map(task => (
              <option key={task.id} value={task.id}>{task.name}</option>
            ))}
          </select>
        </div>
        <button onClick={handleGeneratePassword} className={styles.button}>Generate Password</button>
        {generatedPassword && (
          <p className={styles.generatedPassword}>Generated Password: {generatedPassword}</p>
        )}
      </div>

     <h2>Existing Tasks</h2>
      {tasks.length === 0 ? (
        <p>No tasks available.</p>
      ) : (
        <div className={styles.taskList}>
          {tasks.map(task => (
            <div key={task.id} className={styles.taskItem}>
              <h3>{task.name} ({task.type})</h3>
              <p className={styles.shareUrl}>Share URL: {window.location.origin}/task/{task.id}</p>
              <Link to={task.type === 'photo-recreation' ? `/admin/edit-photo/${task.id}` : `/admin/edit/${task.id}`} className={styles.editLink}>
                Edit
              </Link>
              <button onClick={() => initiateDeleteTask(task)} className={styles.deleteButton}>Delete</button>
            </div>
          ))}
        </div>
      )}

     {showDeleteConfirm && (
        <div className={styles.deleteConfirmOverlay}>
          <div className={styles.deleteConfirmDialog}>
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the task "{taskToDelete.name}"?</p>
            <div className={styles.deleteConfirmButtons}>
              <button onClick={confirmDeleteTask} className={styles.confirmDeleteButton}>Confirm Delete</button>
              <button onClick={() => {
                setShowDeleteConfirm(false);
                setTaskToDelete(null);
              }} className={styles.cancelDeleteButton}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;