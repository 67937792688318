import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post('/api/login', values);
      localStorage.setItem('token', response.data.token);
      navigate('/admin');
    } catch (err) {
      setError('Invalid credentials');
    }
    setSubmitting(false);
  };

  return (
    <div>
      <h1>Login</h1>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div>
              <Field name="email" type="email" placeholder="Email" />
              {errors.email && touched.email && <div>{errors.email}</div>}
            </div>
            <div>
              <Field name="password" type="password" placeholder="Password" />
              {errors.password && touched.password && <div>{errors.password}</div>}
            </div>
            <button type="submit" disabled={isSubmitting}>
              Login
            </button>
            {error && <div>{error}</div>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;