import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './EditTask.module.css';

const TaskSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  clues: Yup.array().of(
    Yup.object().shape({
      content: Yup.string().required('Required'),
      latitude: Yup.number().required('Required').min(-90, 'Must be at least -90').max(90, 'Must be at most 90'),
      longitude: Yup.number().required('Required').min(-180, 'Must be at least -180').max(180, 'Must be at most 180'),
      extraHint: Yup.string(),
      verificationQuestion: Yup.object().shape({
        question: Yup.string().required('Required'),
        answers: Yup.array().of(Yup.string()).min(4, 'Must have 4 answers').max(4, 'Must have 4 answers'),
        correctAnswer: Yup.string().required('Required')
      })
    })
  ),
});

const EditTask = () => {
  const [task, setTask] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { taskId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/admin/tasks/${taskId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const updatedTask = {
        ...response.data,
        clues: response.data.clues.map(clue => ({
          ...clue,
          verificationQuestion: clue.verificationQuestion || {
            question: '',
            answers: ['', '', '', ''],
            correctAnswer: ''
          }
        }))
      };
      
      setTask(updatedTask);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError('Failed to fetch task details. Please try again.');
      setLoading(false);
    }
  };

  const updateTask = async (values, { setSubmitting }) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/admin/tasks/${taskId}`, values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/admin');
    } catch (error) {
      console.error('Error updating task:', error);
      setError('Failed to update task. Please try again.');
    }
    setSubmitting(false);
  };

  const confirmRemoveClue = (remove, index) => {
    if (window.confirm('Are you sure you want to remove this clue?')) {
      remove(index);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!task) return <div>No task data available.</div>;

  return (
    <div className={styles.editTaskContainer}>
      <h2>Edit Task: {task.name}</h2>
      <Formik
        initialValues={task}
        validationSchema={TaskSchema}
        onSubmit={updateTask}
        enableReinitialize
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <div className={styles.fieldGroup}>
              <label htmlFor="name">Task Name</label>
              <Field name="name" className={styles.input} />
              <ErrorMessage name="name" component="div" className={styles.error} />
            </div>
            
            <FieldArray name="clues">
              {({ push, remove }) => (
                <div>
                  {values.clues && values.clues.length > 0 ? (
                    values.clues.map((clue, index) => (
                      <div key={index} className={styles.clueContainer}>
                        <h3>Clue {index + 1}</h3>
                        
                        <div className={styles.fieldGroup}>
                          <label htmlFor={`clues.${index}.content`}>Clue Content</label>
                          <Field name={`clues.${index}.content`} as="textarea" className={styles.textarea} />
                          <ErrorMessage name={`clues.${index}.content`} component="div" className={styles.error} />
                        </div>
                        
                        <div className={styles.fieldGroup}>
                          <label htmlFor={`clues.${index}.latitude`}>Latitude</label>
                          <Field 
                            name={`clues.${index}.latitude`} 
                            type="number" 
                            step="0.000001" 
                            className={styles.input}
                          />
                          <ErrorMessage name={`clues.${index}.latitude`} component="div" className={styles.error} />
                        </div>
                        
                        <div className={styles.fieldGroup}>
                          <label htmlFor={`clues.${index}.longitude`}>Longitude</label>
                          <Field 
                            name={`clues.${index}.longitude`} 
                            type="number" 
                            step="0.000001" 
                            className={styles.input}
                          />
                          <ErrorMessage name={`clues.${index}.longitude`} component="div" className={styles.error} />
                        </div>
                        
                        <div className={styles.fieldGroup}>
                          <label htmlFor={`clues.${index}.extraHint`}>Extra Hint</label>
                          <Field name={`clues.${index}.extraHint`} as="textarea" className={styles.textarea} />
                          <ErrorMessage name={`clues.${index}.extraHint`} component="div" className={styles.error} />
                        </div>
                        
                        <div className={styles.fieldGroup}>
                          <label htmlFor={`clues.${index}.verificationQuestion.question`}>Verification Question</label>
                          <Field name={`clues.${index}.verificationQuestion.question`} as="textarea" className={styles.textarea} />
                          <ErrorMessage name={`clues.${index}.verificationQuestion.question`} component="div" className={styles.error} />
                        </div>
                        
                        {[0, 1, 2, 3].map((answerIndex) => (
                          <div key={answerIndex} className={styles.fieldGroup}>
                            <label htmlFor={`clues.${index}.verificationQuestion.answers[${answerIndex}]`}>Answer {answerIndex + 1}</label>
                            <Field 
                              name={`clues.${index}.verificationQuestion.answers[${answerIndex}]`} 
                              className={styles.input}
                            />
                            <ErrorMessage name={`clues.${index}.verificationQuestion.answers[${answerIndex}]`} component="div" className={styles.error} />
                          </div>
                        ))}
                        
                        <div className={styles.fieldGroup}>
                          <label htmlFor={`clues.${index}.verificationQuestion.correctAnswer`}>Correct Answer</label>
                          <Field as="select" name={`clues.${index}.verificationQuestion.correctAnswer`} className={styles.select}>
                            <option value="">Select Correct Answer</option>
                            {clue.verificationQuestion.answers.map((answer, answerIndex) => (
                              <option key={answerIndex} value={answer}>{answer || `Answer ${answerIndex + 1}`}</option>
                            ))}
                          </Field>
                          <ErrorMessage name={`clues.${index}.verificationQuestion.correctAnswer`} component="div" className={styles.error} />
                        </div>
                        
                        <button type="button" onClick={() => confirmRemoveClue(remove, index)} className={styles.removeButton}>
                          Remove Clue
                        </button>
                      </div>
                    ))
                  ) : (
                    <p>No clues available. Add a clue below.</p>
                  )}
                  <button type="button" onClick={() => push({ 
                    content: '', 
                    latitude: 0, 
                    longitude: 0, 
                    extraHint: '',
                    verificationQuestion: {
                      question: '',
                      answers: ['', '', '', ''],
                      correctAnswer: ''
                    }
                  })} className={styles.addButton}>
                    Add Clue
                  </button>
                </div>
              )}
            </FieldArray>
            <div className={styles.buttonGroup}>
              <button type="submit" disabled={isSubmitting} className={styles.submitButton}>Save Changes</button>
              <button type="button" onClick={() => navigate('/admin')} className={styles.cancelButton}>Cancel</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditTask;