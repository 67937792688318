import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './TreasureHunt.module.css';

const ALLOWED_RADIUS = 10; // 10 meters
const VERIFICATION_RADIUS = 30; // 30 meters
const MAX_INCORRECT_GUESSES = 6;
const EXTRA_HINT_THRESHOLD = 3;
const EXTRA_HINT_TIME_THRESHOLD = 5 * 60; // 5 minutes in seconds
const EXTRA_HINT_DISTANCE_THRESHOLD = 20; // 20 meters
const EXTRA_HINT_TIME_PENALTY = 5 * 60; // 5 minutes in seconds

const TreasureHunt = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const [currentClueIndex, setCurrentClueIndex] = useState(() => {
    const saved = localStorage.getItem(`task_${taskId}_clueIndex`);
    return saved !== null ? parseInt(saved, 10) : 0;
  });
  const [message, setMessage] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(() => {
    const saved = localStorage.getItem(`task_${taskId}_elapsedTime`);
    return saved !== null ? parseInt(saved, 10) : 0;
  });
  const [isRunning, setIsRunning] = useState(() => {
    return localStorage.getItem(`task_${taskId}_isRunning`) === 'true';
  });
  const [isCompleted, setIsCompleted] = useState(() => {
    return localStorage.getItem(`task_${taskId}_isCompleted`) === 'true';
  });
  const [isPasswordEntered, setIsPasswordEntered] = useState(() => {
    return localStorage.getItem(`task_${taskId}_passwordEntered`) === 'true';
  });
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [incorrectGuesses, setIncorrectGuesses] = useState(0);
  const [showExtraHint, setShowExtraHint] = useState(false);
  const [extraHint, setExtraHint] = useState(() => {
    return localStorage.getItem(`task_${taskId}_extraHint`) || '';
  });
  const [locationPermission, setLocationPermission] = useState(null);
  const [extraHintsUsed, setExtraHintsUsed] = useState(() => {
    const saved = localStorage.getItem(`task_${taskId}_extraHintsUsed`);
    return saved ? JSON.parse(saved) : {};
  });

  const [lastGuessTime, setLastGuessTime] = useState(Date.now());
  const [lastClueLocation, setLastClueLocation] = useState(null);
  const [showExtraHintConfirmation, setShowExtraHintConfirmation] = useState(false);
  const [showExtraHintWarning, setShowExtraHintWarning] = useState(false);


  // New state variables for verification question feature
  const [showVerificationQuestion, setShowVerificationQuestion] = useState(false);
  const [verificationQuestion, setVerificationQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState('');

 

  const getOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) return 'Android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'iOS';
    return 'Desktop';
  };

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    if (/edg/i.test(userAgent)) return 'Edge';
    if (/chrome|chromium|crios/i.test(userAgent)) return 'Chrome';
    if (/firefox|fxios/i.test(userAgent)) return 'Firefox';
    if (/safari/i.test(userAgent)) return 'Safari';
    if (/opr\//i.test(userAgent)) return 'Opera';
    return 'your browser';
  };

  useEffect(() => {
    fetchTask();
  }, [taskId]);

  useEffect(() => {
    let interval;
    if (isRunning && !isCompleted) {
      interval = setInterval(() => {
        setElapsedTime(prevTime => {
          const newTime = prevTime + 1;
          localStorage.setItem(`task_${taskId}_elapsedTime`, newTime.toString());
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, isCompleted, taskId]);

  useEffect(() => {
    localStorage.setItem(`task_${taskId}_clueIndex`, currentClueIndex.toString());
    localStorage.setItem(`task_${taskId}_isRunning`, isRunning.toString());
    localStorage.setItem(`task_${taskId}_isCompleted`, isCompleted.toString());
    localStorage.setItem(`task_${taskId}_extraHintsUsed`, JSON.stringify(extraHintsUsed));
    localStorage.setItem(`task_${taskId}_extraHint`, extraHint);
  }, [taskId, currentClueIndex, isRunning, isCompleted, extraHintsUsed, extraHint]);

  useEffect(() => {
    if (isPasswordEntered) {
      checkLocationPermission();
    }
  }, [isPasswordEntered]);

const fetchTask = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/tasks/${taskId}`);
      console.log('Task data received:', response.data);
      
      // Check if the task is of type 'treasure-hunt'
      if (response.data.type !== 'treasure-hunt') {
        // If it's not a treasure hunt, redirect to the photo task
        navigate(`/task/${taskId}`);
        return;
      }
      
      // Update coordinates to 6 decimal places
      const updatedTask = {
        ...response.data,
        clues: response.data.clues.map(clue => ({
          ...clue,
          latitude: parseFloat(clue.latitude).toFixed(6),
          longitude: parseFloat(clue.longitude).toFixed(6)
        }))
      };
      setTask(updatedTask);
      setMessage(null);
    } catch (error) {
      console.error('Error fetching task:', error);
      setMessage('Failed to load task data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/tasks/${taskId}/authenticate`, { password });
      if (response.data.authenticated) {
        setIsPasswordEntered(true);
        setIsRunning(true);
        setMessage(null);
        localStorage.setItem(`task_${taskId}_passwordEntered`, 'true');
      } else {
        setMessage('Incorrect password. Please try again.');
      }
    } catch (error) {
      console.error('Error authenticating:', error);
      setMessage('Failed to authenticate. Please try again.');
    }
  };

const resetGameState = useCallback(() => {
    setCurrentClueIndex(0);
    setElapsedTime(0);
    setIsCompleted(false);
    setIsRunning(true);
    setMessage(null);
    setIsPasswordEntered(true);
    setIncorrectGuesses(0);
    setShowExtraHint(false);
    setExtraHint('');
    setLocationPermission(null);
    setExtraHintsUsed({});
    setGameOver(false);
    setShowVerificationQuestion(false);
    setVerificationQuestion(null);
    setSelectedAnswer('');
    localStorage.setItem(`task_${taskId}_clueIndex`, '0');
    localStorage.setItem(`task_${taskId}_elapsedTime`, '0');
    localStorage.setItem(`task_${taskId}_isRunning`, 'true');
    localStorage.setItem(`task_${taskId}_isCompleted`, 'false');
    localStorage.setItem(`task_${taskId}_passwordEntered`, 'true');
    localStorage.setItem(`task_${taskId}_extraHintsUsed`, '{}');
    localStorage.removeItem(`task_${taskId}_extraHint`);
  }, [taskId]);

  const restartMission = useCallback(() => {
    if (window.confirm('Are you sure you want to restart the mission? Your progress will be lost.')) {
      resetGameState();
    }
  }, [resetGameState]);

  const restartFromOverlay = useCallback((event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    resetGameState();
  }, [resetGameState]);

 const checkLocation = () => {
    if (!task || currentClueIndex >= task.clues.length) return;
    const currentClue = task.clues[currentClueIndex];
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = parseFloat(position.coords.latitude.toFixed(6));
          const userLon = parseFloat(position.coords.longitude.toFixed(6));
          
          const distance = calculateDistance(
            userLat,
            userLon,
            parseFloat(currentClue.latitude),
            parseFloat(currentClue.longitude)
          );
          
          setLastGuessTime(Date.now());
          
          if (distance <= ALLOWED_RADIUS) {
            handleCorrectLocation();
            setLastClueLocation({ latitude: userLat, longitude: userLon });
          } else if (distance <= VERIFICATION_RADIUS) {
            handleNearbyLocation(distance);
          } else {
            handleFarLocation(distance);
          }
        },
        (err) => {
          console.error("Geolocation error:", err);
          setMessage("Unable to retrieve your location. Please enable GPS and try again.");
          setLocationPermission('denied');
        },
        { 
          enableHighAccuracy: true, 
          timeout: 5000, 
          maximumAge: 0 
        }
      );
    } else {
      setMessage("Geolocation is not supported by your browser.");
    }
  };

  const handleCorrectLocation = () => {
    if (currentClueIndex < task.clues.length - 1) {
      setCurrentClueIndex(prevIndex => prevIndex + 1);
      setMessage(null);
      setIncorrectGuesses(0);
      setShowExtraHint(false);
      setExtraHint('');
      setShowVerificationQuestion(false);
      localStorage.removeItem(`task_${taskId}_extraHint`);
    } else {
      setIsCompleted(true);
      setIsRunning(false);
      setMessage(`Congratulations! You've completed all clues in ${formatTime(elapsedTime)}!`);
    }
  };

  const handleNearbyLocation = (distance) => {
    setIncorrectGuesses(prev => {
      const newCount = prev + 1;
      if (newCount >= MAX_INCORRECT_GUESSES) {
        setShowVerificationQuestion(true);
        setVerificationQuestion(task.clues[currentClueIndex].verificationQuestion);
      } else if (newCount >= EXTRA_HINT_THRESHOLD && !extraHintsUsed[currentClueIndex]) {
        setShowExtraHint(true);
      }
      return newCount;
    });
    setMessage("You are close! Please check your surroundings carefully.");
  };

  const handleFarLocation = (distance) => {
    setIncorrectGuesses(prev => {
      const newCount = prev + 1;
      if (newCount >= EXTRA_HINT_THRESHOLD && !extraHintsUsed[currentClueIndex]) {
        setShowExtraHint(true);
      }
      return newCount;
    });
    if (distance < 50) {
      setMessage("You are getting close!");
    } else {
      setMessage("You're not quite there yet.");
    }
  };

  const checkExtraHintConditions = () => {
    const timeSinceLastGuess = (Date.now() - lastGuessTime) / 1000; // Convert to seconds
    const distanceFromLastClue = lastClueLocation ? calculateDistance(
      lastClueLocation.latitude,
      lastClueLocation.longitude,
      parseFloat(currentClue.latitude),
      parseFloat(currentClue.longitude)
    ) : Infinity;

    return (
      incorrectGuesses >= EXTRA_HINT_THRESHOLD ||
      timeSinceLastGuess >= EXTRA_HINT_TIME_THRESHOLD ||
      (distanceFromLastClue <= EXTRA_HINT_DISTANCE_THRESHOLD && timeSinceLastGuess >= 180) // 3 minutes
    );
  };

  const handleExtraHintConfirmation = () => {
    setShowExtraHintWarning(true);
  };

  const confirmExtraHint = () => {
    setElapsedTime(prevTime => prevTime + EXTRA_HINT_TIME_PENALTY);
    getExtraHint();
    setShowExtraHintWarning(false);
  };

  const cancelExtraHint = () => {
    setShowExtraHintWarning(false);
  };

  const getExtraHint = () => {
    if (task && task.clues[currentClueIndex].extraHint) {
      const hint = task.clues[currentClueIndex].extraHint;
      setExtraHint(hint);
      setExtraHintsUsed(prev => ({...prev, [currentClueIndex]: true}));
      setShowExtraHint(false);
      setMessage(null);
      localStorage.setItem(`task_${taskId}_extraHint`, hint);
    } else {
      setExtraHint("No extra hint available for this clue.");
    }
  };

const [gameOver, setGameOver] = useState(false);


  const handleVerificationAnswer = () => {
    if (selectedAnswer === task.clues[currentClueIndex].verificationQuestion.correctAnswer) {
      handleCorrectLocation();
    } else {
      setGameOver(true);
      setIsRunning(false);
      setMessage("Game Over! You answered the verification question incorrectly.");
    }
  };



  const checkLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          setLocationPermission('granted');
        } else if (result.state === 'prompt') {
          setLocationPermission('prompt');
        } else {
          setLocationPermission('denied');
        }
      });
    } else {
      setLocationPermission('unavailable');
    }
  };

  const getLocationInstructions = () => {
    const os = getOS();
    const browser = getBrowser();
    
    let instructions = "To enable location services:\n\n";

    if (os === 'iOS') {
      instructions += "1. Go to Settings > Privacy > Location Services\n";
      instructions += "2. Turn on Location Services\n";
      instructions += `3. Scroll down and turn on location for ${browser}\n`;
    } else if (os === 'Android') {
      instructions += "1. Go to Settings > Location\n";
      instructions += "2. Turn on Use location\n";
      instructions += `3. Ensure ${browser} has permission to use your location\n`;
    } else {
      instructions += "1. Check your browser settings\n";
      instructions += "2. Ensure location services are enabled for this site\n";
    }

    return instructions;
  };

 const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * Math.PI/180;
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // Distance in meters
  };
 

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  if (loading) {
    return <div className={styles.container}>Loading task data...</div>;
  }

  if (!task) {
    return <div className={styles.container}>No task data available.</div>;
  }

  if (gameOver || isCompleted) {
    return (
      <div className={styles.fullScreenOverlay}>
        <div className={styles.overlayContent}>
          <h2>{isCompleted ? "Congratulations!" : "Game Over"}</h2>
          <p>{isCompleted 
            ? `You've completed all clues in ${formatTime(elapsedTime)}!` 
            : "You answered the verification question incorrectly."}
          </p>
          <button onClick={restartFromOverlay} className={styles.restartButton}>
            Restart Mission
          </button>
        </div>
      </div>
    );
  }

  if (!isPasswordEntered) {
    return (
      <div className={styles.passwordContainer}>
        <header className={styles.passwordHeader}>
          <a href="https://www.wejam.studio" target="_blank" rel="noopener noreferrer">
            <img src="/logo.png" alt="WeJam Studio" className={styles.logo} />
          </a>
        </header>
        <h1 className={styles.header}>Enter Password to Access Treasure Hunt</h1>
        <form onSubmit={handlePasswordSubmit} className={styles.passwordForm}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className={styles.passwordInput}
          />
          <button type="submit" className={styles.passwordButton}>Submit</button>
        </form>
        {message && <div className={styles.passwordErrorMessage}>{message}</div>}
      </div>
    );
  }

  if (locationPermission === 'denied' || locationPermission === 'unavailable') {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Location Services Required</h1>
        <p>This treasure hunt requires access to your location. Please enable location services to continue.</p>
        <pre>{getLocationInstructions()}</pre>
        <button onClick={checkLocationPermission} className={styles.button}>Check Again</button>
      </div>
    );
  }

  const currentClue = task.clues[currentClueIndex];

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <a href="https://www.wejam.studio" target="_blank" rel="noopener noreferrer">
          <img src="/logo.png" alt="WeJam Studio" className={styles.logo} />
        </a>
      </header>
      <div className={styles.timerDisplay}>Elapsed Time: {formatTime(elapsedTime)}</div>
     <div className={styles.clueContainer}>
        <h2>Clue {currentClueIndex + 1} of {task.clues.length}</h2>
        <p className={styles.clueContent}>{currentClue.content}</p>
        {checkExtraHintConditions() && !extraHintsUsed[currentClueIndex] && (
          <button onClick={handleExtraHintConfirmation} className={`${styles.locationButton} ${styles.glowingButton}`}>
            Get extra hint
          </button>
        )}
        {extraHint && <p className={`${styles.clueContent} ${styles.extraHint}`}>{extraHint}</p>}
        <button 
          onClick={checkLocation} 
          disabled={isCompleted}
          className={styles.locationButton}
        >
          I'm at the location
        </button>
        {message && <div className={`${styles.message} ${styles.centeredText}`}>{message}</div>}
      </div>

      {showVerificationQuestion && verificationQuestion && (
        <div className={styles.verificationContainer}>
          <p>It looks like we're having trouble pinpointing your exact location. To verify you're in the right place, please answer the following question:</p>
          <h3>{verificationQuestion.question}</h3>
          {verificationQuestion.answers.map((answer, index) => (
            <button 
              key={index} 
              onClick={() => setSelectedAnswer(answer)}
              className={`${styles.answerButton} ${selectedAnswer === answer ? styles.selected : ''}`}
            >
              {answer}
            </button>
          ))}
          <button 
            onClick={handleVerificationAnswer}
            disabled={!selectedAnswer}
            className={styles.locationButton}
          >
            Submit Answer
          </button>
        </div>
      )}
      <div className={styles.restartContainer}>
        <button onClick={restartMission} className={styles.restartButton}>
          Restart Mission
        </button>
      </div>
      {showExtraHintWarning && (
        <div className={styles.overlay}>
          <div className={styles.popup}>
            <h3>Extra Hint Warning</h3>
            <p>Using an extra hint will add 5 minutes to your elapsed time. Do you want to continue?</p>
            <div className={styles.popupButtons}>
              <button onClick={confirmExtraHint} className={styles.confirmButton}>Yes, use hint</button>
              <button onClick={cancelExtraHint} className={styles.cancelButton}>No, cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TreasureHunt;