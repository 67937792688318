import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './PhotoTask.module.css';

const PhotoTask = () => {
  const [task, setTask] = useState(null);
  const [error, setError] = useState(null);
  const [stage, setStage] = useState('password');
  const [password, setPassword] = useState('');
  const [teamName, setTeamName] = useState('');
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { taskId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskDetails = async () => {
    try {
      const response = await axios.get(`/api/tasks/${taskId}`);
      if (response.data.type !== 'photo-recreation') {
        // If it's not a photo task, redirect to the correct route
        navigate(`/hunt/${taskId}`);
        return;
      }
      setTask(response.data);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError('Failed to fetch task details. Please try again.');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/tasks/${taskId}/authenticate`, { password });
      if (response.data.authenticated) {
        setStage('teamName');
        setError(null);
      } else {
        setError('Incorrect password. Please try again.');
      }
    } catch (error) {
      console.error('Error authenticating:', error);
      setError('Failed to authenticate. Please try again.');
    }
  };

  const handleTeamNameSubmit = (e) => {
    e.preventDefault();
    if (/^[a-zA-Z0-9\s'''‛`-]+$/.test(teamName)) {
      setStage('photos');
      setError(null);
    } else {
      setError("Team name can only contain letters, numbers, spaces, apostrophes, and hyphens.");
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file.');
      return;
    }

    setIsUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append('photo', file);
    formData.append('teamName', teamName);
    formData.append('photoIndex', currentPhotoIndex + 1);

    try {
      await axios.post(`/api/tasks/${taskId}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUploadedPhotos([...uploadedPhotos, currentPhotoIndex]);
      if (currentPhotoIndex < task.photos.length - 1) {
        setCurrentPhotoIndex(currentPhotoIndex + 1);
      } else {
        alert('Congratulations! You have completed all the photos for this task.');
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError('Failed to upload photo. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleBack = () => {
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    }
  };

  const handleForward = () => {
    if (currentPhotoIndex < task.photos.length - 1) {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    }
  };

  if (!task) return <div className={styles.container}>Loading...</div>;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <a href="https://www.wejam.studio" target="_blank" rel="noopener noreferrer">
          <img src="/logo.png" alt="WeJam Studio" className={styles.logo} />
        </a>
      </header>

      {stage === 'password' && (
        <div className={styles.passwordContainer}>
          <h1 className={styles.header}>Enter Password to Access Photo Task</h1>
          <form onSubmit={handlePasswordSubmit} className={styles.passwordForm}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className={styles.passwordInput}
            />
            <button type="submit" className={styles.passwordButton}>Submit</button>
          </form>
          {error && <div className={styles.passwordErrorMessage}>{error}</div>}
        </div>
      )}

      {stage === 'teamName' && (
        <div className={styles.welcomeContainer}>
          <h1 className={styles.header}>Welcome to the Album Cover challenge!</h1>
          <p className={styles.welcomeText}>{task.welcomeText}</p>
          <form onSubmit={handleTeamNameSubmit} className={styles.teamNameForm}>
            <input
              type="text"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              placeholder="Enter your team name"
              className={styles.teamNameInput}
            />
            <button type="submit" className={styles.teamNameButton}>Start Task</button>
          </form>
          {error && <div className={styles.teamNameErrorMessage}>{error}</div>}
        </div>
      )}

      {stage === 'photos' && (
        <>
          <div className={styles.photoContainer}>
            <img 
              src={`/uploads/${taskId}/${task.photos[currentPhotoIndex].filename}`} 
              alt={`Task photo ${currentPhotoIndex + 1}`} 
              className={styles.taskPhoto}
            />
            <p className={styles.photoCaption}>{task.photos[currentPhotoIndex].caption}</p>
            {isUploading && <div className={styles.uploadingOverlay}>Uploading...</div>}
          </div>
          <div className={styles.uploadContainer}>
            <label htmlFor="fileInput" className={styles.uploadButton}>
              {uploadedPhotos.includes(currentPhotoIndex) ? 'Re-upload Photo' : 'Choose and Upload Photo'}
              <input 
                id="fileInput"
                type="file" 
                onChange={handleFileUpload} 
                accept="image/*" 
                className={styles.fileInput}
              />
            </label>
          </div>
          {error && <p className={styles.errorMessage}>{error}</p>}
          <div className={styles.progressDisplay}>
            <div className={styles.navButtonContainer}>
              {currentPhotoIndex > 0 && (
                <button onClick={handleBack} className={styles.navButton}>
                  &#8592;
                </button>
              )}
            </div>
            <span className={styles.photoNumber}>Photo {currentPhotoIndex + 1} of {task.photos.length}</span>
            <div className={styles.navButtonContainer}>
              {uploadedPhotos.includes(currentPhotoIndex) && currentPhotoIndex < task.photos.length - 1 && (
                <button onClick={handleForward} className={styles.navButton}>
                  &#8594;
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PhotoTask;