import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const CreateAdminSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
  secretKey: Yup.string().required('Required'),
});

const CreateAdmin = () => {
  const [message, setMessage] = useState('');

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post('/api/create-admin', values);
      setMessage(response.data.message);
    } catch (err) {
      setMessage(err.response?.data?.error || 'An error occurred');
    }
    setSubmitting(false);
  };

  return (
    <div>
      <h1>Create Admin User</h1>
      <Formik
        initialValues={{ email: '', password: '', secretKey: '' }}
        validationSchema={CreateAdminSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div>
              <Field name="email" type="email" placeholder="Email" />
              {errors.email && touched.email && <div>{errors.email}</div>}
            </div>
            <div>
              <Field name="password" type="password" placeholder="Password" />
              {errors.password && touched.password && <div>{errors.password}</div>}
            </div>
            <div>
              <Field name="secretKey" type="password" placeholder="Secret Key" />
              {errors.secretKey && touched.secretKey && <div>{errors.secretKey}</div>}
            </div>
            <button type="submit" disabled={isSubmitting}>
              Create Admin
            </button>
          </Form>
        )}
      </Formik>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CreateAdmin;