import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TreasureHunt from './components/TreasureHunt';
import PhotoTask from './components/PhotoTask';
import Admin from './components/Admin';
import Login from './components/Login';
import EditTask from './components/EditTask';
import EditPhotoTask from './components/EditPhotoTask';
import CreateAdmin from './components/CreateAdmin';
import './fonts.css';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  return (
    <Router>
      <Routes>

        {/* Public routes */}
       <Route path="/" element={
          <>
            <h1>Welcome to Treasure Hunt</h1>
            <p>Select a hunt to begin!</p>
          </>
        } />
        <Route path="/hunt/:taskId" element={<TreasureHunt />} />
        <Route path="/login" element={<Login />} />
        <Route path="/task/:taskId" element={<PhotoTask />} />
        
      {/* Protected routes */}
        <Route path="/admin" element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        } />
        <Route path="/admin/edit/:taskId" element={
          <ProtectedRoute>
            <EditTask />
          </ProtectedRoute>
        } />
        <Route path="/admin/edit-photo/:taskId" element={
          <ProtectedRoute>
            <EditPhotoTask />
          </ProtectedRoute>
        } />
        <Route path="/create-admin" element={
          <ProtectedRoute>
            <CreateAdmin />
          </ProtectedRoute>
        } />
        
        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={
          <div>
            <h1>404: Page Not Found</h1>
            <p>The page you're looking for doesn't exist.</p>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;